import { DeviceType, phoneNumber } from './constants';

import { getDeviceTypeAsync } from 'expo-device';
import * as Linking from 'expo-linking';

export { callCarrum } from 'app/util/call';

export { getSavedSingleClickRegistrations } from 'app/util/userUtils';

export const goToPage = (destinationUrl: string) => {
  Linking.openURL(destinationUrl);
};
/** Whether the device is a phone or tablet. */
export const isMobileDevice = async () => {
  const deviceType = await getDeviceTypeAsync();
  return deviceType === DeviceType.PHONE || deviceType === DeviceType.TABLET;
};
export { logEvent } from 'app/util/analytics';
/** If on a mobile device, launch a call. */
export const makeCall = async () => {
  if (!isMobileDevice) return;
  Linking.openURL(`tel:${phoneNumber}`);
};
export { trimWhitespace } from 'app/util/methods';
