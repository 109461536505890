import { useState } from 'app/components/Messages/hooks';

import {
  ScrollView,
  MessagesHeaderContainer,
  MessagesHeaderContent,
  MessagesHeaderRow,
  MessagesHeaderTitle,
  MessagesHeaderUserStatus,
  MessagesHeaderUserText,
  Holiday,
} from 'app/components/Messages/styles';

import { MessagesHeaderProps } from 'app/components/Messages/types';

import {
  FeatureSwitch,
  HolidayModal,
  ScheduleCallButton,
} from './subcomponents';
import { View } from 'react-native';

export const MessagesHeader = ({
  isRendered = false,
  participants,
}: MessagesHeaderProps) => {
  const [holidayModalIsVisible, setHolidayModalIsVisible] = useState(false);

  if (!isRendered) return null;

  return (
    <MessagesHeaderContainer>
      <MessagesHeaderContent>
        <MessagesHeaderRow>
          <MessagesHeaderTitle>My Care Team</MessagesHeaderTitle>

          <FeatureSwitch name="holiday-hours-message" state="on">
            <Holiday
              onPress={() => {
                setHolidayModalIsVisible(true);
              }}
            />
            <HolidayModal
              onClose={() => setHolidayModalIsVisible(false)}
              visible={holidayModalIsVisible}
            />
          </FeatureSwitch>
        </MessagesHeaderRow>

        <ScrollView horizontal>
          {participants.map(({ id, name, status }) => (
            <MessagesHeaderRow key={id}>
              {/* @ts-expect-error No overload matches this call */}
              <MessagesHeaderUserStatus status={status} />

              <MessagesHeaderUserText>{name}</MessagesHeaderUserText>
            </MessagesHeaderRow>
          ))}
        </ScrollView>
        <View>
          <ScheduleCallButton />
        </View>
      </MessagesHeaderContent>
    </MessagesHeaderContainer>
  );
};
