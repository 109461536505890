import theme from 'app/util/theme';
import React from 'react';
import Svg, { Circle, Line, Path, Rect } from 'react-native-svg';

interface Props {
  height?: number;
  width?: number;
}

const BenefitCircleNoSavings = ({ height = 47, width = 47 }: Props) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 47 47" fill="none">
      <Circle cx="23.5" cy="23.5" r="23.5" fill={theme.colors.default} />
      <Path
        d="M23.5 9.79688C16.2754 9.79688 10.4219 15.6504 10.4219 22.875C10.4219 30.0996 16.2754 35.9531 23.5 35.9531C30.7246 35.9531 36.5781 30.0996 36.5781 22.875C36.5781 15.6504 30.7246 9.79688 23.5 9.79688ZM24.7656 29.625V30.4688C24.7656 30.9434 24.3438 31.3125 23.9219 31.3125H23.0781C22.6035 31.3125 22.2344 30.9434 22.2344 30.4688V29.625C21.3379 29.625 20.4941 29.3086 19.7559 28.834C19.2812 28.5176 19.2285 27.8848 19.6504 27.5156L20.2832 26.9355C20.5469 26.6719 20.9688 26.6191 21.3379 26.8301C21.6543 27.041 21.9707 27.0938 22.3398 27.0938H24.7129C25.3457 27.0938 25.8203 26.5664 25.8203 25.8809C25.8203 25.3008 25.5039 24.8262 25.0293 24.668L21.2324 23.5078C19.7031 23.0859 18.5957 21.5566 18.5957 19.9219C18.5957 17.8652 20.2305 16.1777 22.2344 16.125V15.2812C22.2344 14.8594 22.6035 14.4375 23.0781 14.4375H23.9219C24.3438 14.4375 24.7656 14.8594 24.7656 15.2812V16.1777C25.6094 16.1777 26.4531 16.4414 27.1914 16.9688C27.666 17.2852 27.7188 17.918 27.2969 18.2871L26.6641 18.8672C26.4004 19.1309 25.9785 19.1836 25.6621 18.9727C25.293 18.7617 24.9766 18.6562 24.6074 18.6562H22.2344C21.6016 18.6562 21.127 19.2363 21.127 19.9219C21.127 20.4492 21.4434 20.9766 21.918 21.1348L25.7148 22.2422C27.2441 22.7168 28.3516 24.1934 28.3516 25.8809C28.3516 27.9375 26.7168 29.625 24.7656 29.625Z"
        fill="white"
      />
      <Rect x="10" y="33" width="27" height="4" rx="2" fill="white" />
      <Line
        x1="10.7678"
        y1="8.23223"
        x2="40.7678"
        y2="38.2322"
        stroke={theme.colors.default}
        stroke-width="5"
      />
    </Svg>
  );
};

export default BenefitCircleNoSavings;
