import theme from 'app/util/theme';
import React from 'react';
import Svg, { Circle, Line, Path } from 'react-native-svg';

interface Props {
  height?: number;
  width?: number;
}

const BenefitCircleNoCare = ({ height = 47, width = 47 }: Props) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 47 47" fill="none">
      <Path
        d="M24.6418 9.21875C16.6144 9.21875 10.1105 15.7227 10.1105 23.75C10.1105 31.7773 16.6144 38.2812 24.6418 38.2812C32.6691 38.2812 39.173 31.7773 39.173 23.75C39.173 15.7227 32.6691 9.21875 24.6418 9.21875ZM29.3293 19.0625C30.3254 19.0625 31.2043 19.9414 31.2043 20.9375C31.2043 21.9922 30.3254 22.8125 29.3293 22.8125C28.2746 22.8125 27.4543 21.9922 27.4543 20.9375C27.4543 19.9414 28.2746 19.0625 29.3293 19.0625ZM19.9543 19.0625C20.9504 19.0625 21.8293 19.9414 21.8293 20.9375C21.8293 21.9922 20.9504 22.8125 19.9543 22.8125C18.8996 22.8125 18.0793 21.9922 18.0793 20.9375C18.0793 19.9414 18.8996 19.0625 19.9543 19.0625ZM31.3215 29.082C29.6809 31.0742 27.2199 32.1875 24.6418 32.1875C22.0051 32.1875 19.5441 31.0742 17.9035 29.082C17.0832 28.0859 18.548 26.9141 19.3098 27.8516C20.6574 29.4336 22.591 30.3125 24.6418 30.3125C26.6926 30.3125 28.5676 29.4336 29.9152 27.8516C30.6769 26.9141 32.1418 28.0859 31.3215 29.082Z"
        fill="white"
      />
      <Line
        x1="9.41421"
        y1="8.58579"
        x2="39.4142"
        y2="38.5858"
        stroke={theme.colors.default}
        stroke-width="4"
      />
      <Circle cx="23.5" cy="23.5" r="23.5" fill={theme.colors.default} />
      <Path
        d="M30.6418 22.75V14C30.6418 12.0859 29.0559 10.5 27.1418 10.5H11.3918C9.42304 10.5 7.89179 12.0859 7.89179 14V22.75C7.89179 24.7188 9.42304 26.25 11.3918 26.25V29.2578C11.3918 29.6953 11.884 29.9141 12.2121 29.6406L16.7512 26.25H27.1418C29.0559 26.25 30.6418 24.7188 30.6418 22.75ZM35.8918 19.25H32.3918V22.75C32.3918 25.6484 29.9855 28 27.1418 28H18.3918V31.5C18.3918 33.4688 19.923 35 21.8918 35H28.7277L33.2668 38.4453C33.5949 38.6641 34.1418 38.4453 34.1418 38.0078V35H35.8918C37.8059 35 39.3918 33.4688 39.3918 31.5V22.75C39.3918 20.8359 37.8059 19.25 35.8918 19.25Z"
        fill="white"
      />
      <Circle cx="14" cy="18.5" r="1.5" fill={theme.colors.default} />
      <Circle cx="19" cy="18.5" r="1.5" fill={theme.colors.default} />
      <Circle cx="24" cy="18.5" r="1.5" fill={theme.colors.default} />
      <Line
        x1="8.76777"
        y1="8.23223"
        x2="38.7678"
        y2="38.2322"
        stroke={theme.colors.default}
        stroke-width="5"
      />
    </Svg>
  );
};

export default BenefitCircleNoCare;
