import { useCallback, useEffect, useState } from 'react';

import { UseValidationsHook, UseValidationsHookParams } from './types';

/**
 * A hook that works with `app/util/validations` to handle validating form
 * values and surfacing errors within components.
 *
 * @example
 * import { useValidations } from 'app/hooks';
 * import { isFormat, isPresent } from 'app/util/validations';
 *
 * const validations = {
 *   email: [isPresent(), isFormat(/@/)],
 *   name: [isPresent()],
 * };
 *
 * export const MyComponent = () => {
 *   const [formValues, setFormValues] = useState({ email: '', name: '' });
 *   const { errors, isValid } = useValidations({ formValues, validations });
 *
 *   // `errors` will be key/value pairs matching entries in `formValues`.
 *   console.log({ errors }); // { errors: { email: 'is required', firstName: 'is required' } };
 *
 *   // `isValid` will be `false` if there are any error messages.
 *   console.log({ isValid }); // { isValid: false };
 * };
 */
export const useValidations = ({
  formValues,
  validations,
}: UseValidationsHookParams): UseValidationsHook => {
  const [errors, setErrors] = useState({});

  const validateField = useCallback(
    (field: string) => {
      if (!validations[field]) return;

      /**
       * Iterate over each validation for the given field and stop at the first
       * failure (if any).
       */
      validations[field].some((validation) => {
        const error = validation(formValues[field], formValues);
        const newErrors = { ...errors };

        if (error) {
          newErrors[field] = error;
        } else {
          delete newErrors[field];
        }

        setErrors(newErrors);

        // Return `true` if there was an error so `.some()` exits early.
        return Boolean(newErrors[field]);
      });
    },
    [errors, formValues, validations]
  );

  const isIncomplete = Object.values(formValues).some(
    (value) => typeof value === 'undefined'
  );

  const isValid = Object.keys(errors).length === 0 && !isIncomplete;

  /**
   * Set up a `useEffect()` hook for each item in the `validations` object and
   * automatically run the validation when the corresponding value in the
   * `formValues` property changes.
   */
  Object.keys(validations).forEach((key) => {
    useEffect(() => {
      // If value is `undefined`, consider it "pristine" and don't validate.
      if (formValues[key] === undefined) return;

      validateField(key);
    }, [formValues[key], validations]);
  });

  return {
    errors,
    isValid,
    validateField,
  };
};

export default useValidations;
