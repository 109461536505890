import React, { ReactNode, useEffect } from 'react';
import {
  StyleProp,
  StyleSheet,
  useWindowDimensions,
  ViewStyle,
} from 'react-native';

import { isEmpty } from 'lodash';
import { Overlay, OverlayProps } from 'react-native-elements';

import { DotIndicator } from 'app/components/Common/svg-components';

import { playSound } from 'app/util/soundUtils';
import theme from 'app/util/theme';
import { TestID } from 'app/util/test-id';
import { Palette } from 'types/theme';

import { getPadding, getWidth } from './helpers';
import {
  ButtonsWrapper,
  CloseIcon,
  CloseIconButton,
  ContentScrollWrapper,
  DisclaimerText,
  ModalCTAButton,
  Title,
} from './styles';
import { ModalOption, ModalSize } from './types';

export { ModalOption } from './types';

/**
 * General Notes:
 * This modal is highly opinionated by default. The following props should
 * be used sparingly, and only after confirming intent with Design/Aimee:
 *   * `backgroundColor` is an override to the default and intended background color.
 *   * `fullScreen` does NOT use the `<Overlay />`'s provided `fullScreen` prop, because we do style overrides that
 *      break it. Our implementation makes the modal full screen in both mobile and desktop views, and it leaves
 *      all internal styling (including padding) up to the children passed in.
 *   * `contentStyleOverrides` is used to override the first child of the Modal Overlay. See `overlayStyleOverrides` for more detail.
 *   * `overlayStyleOverrides` is used to override styles of the modal itself. Applying styles to the parent-most node of the
 *     <Overlay /> does not give the same result. That said, modal styling is meant to be opinionated and consistent
 *     across the app. Deviations tend to result in tech debt and should be generally avoided.
 *     Instead, to update modal styling across all modals, consider adding style rules to the `overlay` theme property
 *     in `app/util/theme.ts` or to the `responsiveOverlayStyles` object declared in this file.
 *
 * Also, the `showCloseX` prop is intentionally overridden when there are no options passed. This is to prevent
 *   situations where the user could get stuck in the modal.
 */
export interface ModalProps extends OverlayProps {
  backgroundColor?: keyof Palette;
  children?: ReactNode;
  contentStyleOverrides?: ViewStyle;
  disclaimer?: ReactNode;
  fullScreen?: boolean;
  isVisible: boolean;
  onClose: () => void;
  options?: ModalOption[];
  overlayStyleOverrides?: StyleProp<ViewStyle>;
  showCloseX?: boolean;
  size?: ModalSize;
  sound?: string;
  testID?: string;
  title?: string;
}

export const Modal = ({
  backgroundColor = 'sand',
  children,
  contentStyleOverrides = {},
  disclaimer = null,
  fullScreen = false,
  onClose,
  options = [],
  overlayStyleOverrides = {},
  showCloseX = true,
  size = 'small',
  sound = '',
  testID = TestID.Modal.Component,
  title = '',
  ...overlayProps
}: ModalProps) => {
  useEffect(() => {
    if (!overlayProps.isVisible || !sound) return;

    playSound(sound);
  }, [overlayProps.isVisible, sound]);

  const stackedButtons = options.length > 1;

  const { height: screenHeight, width: screenWidth } = useWindowDimensions();

  const responsiveOverlayStyles = {
    justifyContent: 'space-between',
    padding: getPadding(size, screenWidth),
    width: getWidth(size, screenWidth),
  } as Record<string, unknown>;

  // If the screen is mobile-sized, then the min-height should be set to near-full screen.
  if (screenWidth < theme.breakpoints.small) {
    responsiveOverlayStyles.maxHeight = screenHeight - theme.navigationHeight;
    responsiveOverlayStyles.minHeight =
      screenHeight - theme.navigationHeight - theme.spacing * 0.75;
  }

  // The background color of the modal defaults to sand but can be overridden (typically to 'white').
  if (backgroundColor) {
    responsiveOverlayStyles.backgroundColor = theme.colors[backgroundColor];
  }

  if (fullScreen) {
    responsiveOverlayStyles.width = screenWidth;
    responsiveOverlayStyles.height = screenHeight;
    responsiveOverlayStyles.padding = 0;
  }

  return (
    <Overlay
      onBackdropPress={onClose}
      overlayStyle={StyleSheet.flatten([
        responsiveOverlayStyles,
        overlayStyleOverrides,
      ])}
      {...overlayProps}
    >
      <ContentScrollWrapper style={contentStyleOverrides} testID={testID}>
        {!!title && <Title>{title}</Title>}

        {!isEmpty(children) && children}
      </ContentScrollWrapper>

      {!isEmpty(options) && (
        <ButtonsWrapper
          hasMarginTop={!isEmpty(children)}
          stackedButtons={stackedButtons}
        >
          {options.map((option, index) => (
            <ModalCTAButton
              disabled={option.isLoading}
              icon={
                option.isLoading && (
                  <DotIndicator
                    color={option.loadingColor || theme.colors.white}
                  />
                )
              }
              keepCasing={option.keepCasing}
              key={index}
              stackButtons={stackedButtons}
              testID={TestID.Modal.CloseButton}
              title={option.isLoading ? '' : option.title}
              type={option.type ? option.type : 'outline'}
              {...option}
            />
          ))}
        </ButtonsWrapper>
      )}

      {!!disclaimer && (
        <DisclaimerText align="center">{disclaimer}</DisclaimerText>
      )}

      {/* The CloseIconButton can be hidden with the `showCloseX` flag, but it should show if there are no option CTAs.
      This prevents the modal from being impossible to close, especially when the modal is full screen on a mobile device */}
      {(isEmpty(options) || showCloseX) && (
        <CloseIconButton
          modalSize={size}
          onPress={onClose}
          testID={TestID.Modal.CloseIcon}
        >
          <CloseIcon />
        </CloseIconButton>
      )}
    </Overlay>
  );
};

export default Modal;
