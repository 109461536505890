export { default as defaultAuthorProfileImage } from 'app/assets/images/anonymous.jpg';

export const initialFormValues = { body: '', attachments: [] };

export { EVENTS } from 'app/util/analytics';

export const messagesPerPage = 20;

export const redrawTimeout = 50;

export { TestID } from 'app/util/test-id';

export { default as theme } from 'app/util/theme';

export { useNativeDriver } from 'app/util/constants';

export { zIndexes } from 'app/util/theme';
