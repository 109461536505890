import { TestID, theme } from 'app/components/App/Navigation/constants';

import {
  extractUnreadMessageCount,
  receiveSetLiveChatModalVisibility,
} from 'app/components/App/Navigation/helpers';

import {
  useDispatch,
  useMessage,
  useSession,
  useUi,
  useWindowDimensions,
} from 'app/components/App/Navigation/hooks';

import {
  IdleMoon,
  LiveChatIcon,
  ChatButtonStyles as Styled,
} from 'app/components/App/Navigation/styles';

import { LiveChatModal } from './subcomponents';

export const ChatButton = () => {
  const dispatch = useDispatch();
  const { isLiveChatModalVisible } = useUi();
  const message = useMessage();
  const { user } = useSession();
  const windowDimensions = useWindowDimensions();

  const conversation = message.conversations[0];

  const unreadMessagesCount = conversation
    ? extractUnreadMessageCount(user.id, conversation.messages)
    : 0;

  const buttonIsVisible = windowDimensions.width > theme.breakpoints.small;
  const hasMessages = unreadMessagesCount > 0;
  const buttonText = hasMessages ? 'Talk to Us' : 'Need Help?';

  const hourInPacificTime = new Date().getUTCHours() - 7;
  const isWithinBusinesshours =
    hourInPacificTime >= 9 && hourInPacificTime < 17;

  const subheaderText = isWithinBusinesshours
    ? 'Care Team is active'
    : 'After hours';

  const statusIcon = isWithinBusinesshours ? <Styled.GreenDot /> : <IdleMoon />;

  return (
    <>
      {buttonIsVisible && (
        <Styled.Button
          onPress={() => dispatch(receiveSetLiveChatModalVisibility(true))}
          icon={<LiveChatIcon />}
          testID={TestID.Navigation.ChatButton}
          type="outline"
          title={
            <>
              <Styled.ButtonTextContainer>
                <Styled.ButtonText>
                  {buttonText}
                  {hasMessages && <Styled.Badge value={unreadMessagesCount} />}
                </Styled.ButtonText>

                <Styled.ButtonSubheaderContainer>
                  {statusIcon}
                  <Styled.ButtonSubheaderText>
                    {subheaderText}
                  </Styled.ButtonSubheaderText>
                </Styled.ButtonSubheaderContainer>
              </Styled.ButtonTextContainer>
            </>
          }
        />
      )}

      <LiveChatModal
        onClose={() => dispatch(receiveSetLiveChatModalVisibility(false))}
        visible={isLiveChatModalVisible}
      />
    </>
  );
};
