import React from 'react';
import { Pressable } from 'react-native';
import { StyledView } from './styles';

interface ResponsivePressableProps {
  children: any;
  flexDirection?: 'row' | 'column';
  pressableStyle?: any;
}

export const ResponsivePressable = ({
  children,
  flexDirection,
  ...props
}: ResponsivePressableProps) => {
  return (
    <Pressable {...props}>
      {({ pressed }) => (
        <StyledView
          flexDirection={flexDirection}
          pressed={pressed}
          style={props.pressableStyle}
        >
          {children}
        </StyledView>
      )}
    </Pressable>
  );
};
