import { EVENTS } from './constants';

import { isImage, isVideo } from 'app/util/methods';

import { logEvent } from 'app/util/analytics';

import { playSound } from './helpers';

import { Platform } from './styles';

import { composeConversation } from 'app/util/messageUtils';

import {
  connectToWebSocket as connectToWebSocketAction,
  createMessage as createMessageAction,
  fetchConversations as fetchConversationsAction,
  fetchMessages as fetchMessagesAction,
  markUnreadMessages as markUnreadMessagesAction,
  setMessagesOpen as setMessagesOpenAction,
} from 'app/actions/messageActions';

import {
  launchCamera as launchCameraAction,
  launchDocumentPicker as launchDocumentPickerAction,
  launchImageLibrary as launchImageLibraryAction,
} from 'app/actions/uiActions';

const fetchConversations = (dispatch) => dispatch(fetchConversationsAction());

const fetchMessages = (dispatch, conversationId, maxId) =>
  dispatch(fetchMessagesAction(conversationId, maxId));

const launchCamera = (dispatch, options) =>
  dispatch(launchCameraAction(options));

const launchDocumentPicker = (dispatch) =>
  dispatch(launchDocumentPickerAction());

const launchImageLibrary = (dispatch, options) =>
  dispatch(launchImageLibraryAction(options));

export { callCarrum } from 'app/util/call';

/** Play a chime to indicate a new message. */
export const chime = () => {
  playSound('received');
};

export const connectToWebSocket = (dispatch) =>
  dispatch(connectToWebSocketAction());

export const createMessage = (dispatch, conversationId, message) =>
  dispatch(createMessageAction(conversationId, message));

/**
 * Fetch the next page of messages with a 300 ms debounce.
 *
 * @return  {promise}  a promise that resolves after fetching more messages
 */
export const fetchMoreMessages = async (
  dispatch,
  conversation,
  refreshing,
  setRefreshing,
  refreshTimeout
) => {
  if (refreshing || !conversation || !conversation.messages.length) return;

  const finalMessageIndex = conversation.messages.length - 1;
  const finalMessage = conversation.messages[finalMessageIndex];

  // Preserve location in the current scroll index.
  // @ts-ignore TODO: fix `Type '"instant"' is not assignable to type 'ScrollBehavior'`
  if (Platform.OS === 'web') window.scrollTo({ top: 1, behavior: 'instant' });

  setRefreshing(true);

  await fetchMessages(dispatch, conversation.id, finalMessage.id);

  refreshTimeout = setTimeout(() => setRefreshing(false), 300);
};

export { formatPhoneNumber } from 'app/util/methods';

export const getConversation = (message, session) => {
  if (!message.conversationId) return null;

  const correspondingConversation = message.conversations.find(
    ({ id }) => parseInt(id) === parseInt(message.conversationId)
  );

  return composeConversation(
    correspondingConversation,
    message.userStatuses,
    session.user.id
  );
};

/**
 * Fetch any conversations for the current user, and scroll to the beginning
 * of the list on web.
 */
export const getConversations = async ({
  conversation,
  dispatch,
  redrawTimeout,
  scrollView,
  setIsLoading,
}) => {
  await fetchConversations(dispatch);

  setIsLoading(false);

  if (Platform.OS !== 'web' || !conversation) return;

  // Wait for all content to load to allow browsers to calculate the height.
  setTimeout(() => scrollToBottom(false, scrollView.current), redrawTimeout);
};

export const getMessageGroups = (conversation) =>
  conversation ? conversation.messageGroups : {};

export const getMessagesData = (messageGroups) => {
  return Object.keys(messageGroups).map((key, idx) => ({
    key,
    messageIsLastInConversation: idx === 0,
    ...messageGroups[key],
  }));
};

/**
 * Get the correct style for a message based on its position within a message
 * group with a given number of messages.
 *
 * @param   {integer}  index            the index of this message in the group
 * @param   {integer}  count            total number of messages in this group
 * @param   {boolean}  patientIsAuthor  true when current user is the author
 *
 * @return  {object}                    the style object for the message
 */
export const getMessageTextWrapperStyleParams = (
  index,
  count,
  patientIsAuthor
) => ({
  isFirst: count > 1 && index === 0,
  isLast: count > 1 && index === count - 1,
  isMiddle: count > 2 && index < count - 1 && index > 0,
  patientIsAuthor: patientIsAuthor,
});

export const getParticipants = (conversation): [] =>
  conversation ? conversation.participants : [];

export const getShowLoadMoreButton = (conversation, messagesPerPage) =>
  Platform.OS === 'web' &&
  conversation &&
  conversation.messages.length >= messagesPerPage;

/**
 * Launch the camera roll to allow users to send photos already on their
 * device.
 */
export const handleAlbumPress = async ({
  dispatch,
  conversation,
  formValues,
  setFormValues,
}) => {
  if (!conversation) return null;

  const { canceled, assets } = await launchImageLibrary(dispatch, {
    allowsEditing: true,
    mediaTypes: 'All',
  });

  if (canceled) return;

  setFormValues({
    ...formValues,
    attachments: [...formValues.attachments, ...assets.map(({ uri }) => uri)],
  });
};

/**
 * Launch the camera app to allow users to take and send photos using their
 * device.
 */
export const handleCameraPress = async ({
  dispatch,
  conversation,
  formValues,
  setFormValues,
}) => {
  if (!conversation) return null;

  const { canceled, assets } = await launchCamera(dispatch, {
    allowsEditing: true,
    mediaTypes: 'All',
  });

  if (canceled) return;

  setFormValues({
    ...formValues,
    attachments: [...formValues.attachments, ...assets.map(({ uri }) => uri)],
  });
};

/**
 * Launch the file explorer to allow users to upload documents stored on
 * their device.
 */
export const handleDocumentPress = async ({
  dispatch,
  conversation,
  formValues,
  setFormValues,
}) => {
  if (!conversation) return null;

  const { canceled, assets } = await launchDocumentPicker(dispatch);

  if (canceled) return;

  setFormValues({
    ...formValues,
    attachments: [...formValues.attachments, assets[0].uri],
  });
};

/**
 * Determine whether there is a new message from another user.
 *
 * @param   {string}  currentUserId         the identifier for the current user
 * @param   {object}  conversation          data for the conversation as it is
 * @param   {object}  previousConversation  data for the conversation as it was
 *
 * @return  {boolean}                       whether there is a new message
 */
export const hasNewMessage = (
  currentUserId,
  conversation,
  previousConversation
) => {
  if (!conversation) return false;

  const lastMessage = conversation.messages[0];
  const lastMessageExists = Boolean(lastMessage);
  const userDidNotAuthorLastMessage =
    parseInt(lastMessage?.author.id, 10) !== parseInt(currentUserId, 10);

  const conversationIsNew = Boolean(!previousConversation && conversation);
  const conversationHasMoreMessages =
    conversation?.messages?.length > previousConversation?.messages?.length;
  const conversationHasBeenUpdated =
    conversationIsNew || conversationHasMoreMessages;

  const result =
    conversationHasBeenUpdated &&
    lastMessageExists &&
    userDidNotAuthorLastMessage;

  return result;
};

export { logEvent };

/**
 * Log messaging events in Google Analytics.
 *
 * @param  {string}  body         the message body
 * @param  {array}   attachments  the message attachments
 */
export const logMessageSent = (body, attachments) => {
  if (body.length) logEvent(EVENTS.messaging.sendMessage);

  attachments.forEach((attachment) => {
    const type = isVideo(attachment)
      ? 'video'
      : isImage(attachment)
      ? 'image'
      : 'document';

    logEvent(EVENTS.messaging.sendAttachment, { type });
  });
};

/**
 * Mark unread messages as read after an optional timeout.
 *
 * @param  {integer}  timeout  the number of milliseconds to wait before
 *                             updating unread messages
 */
export const markUnreadMessages = (
  dispatch,
  conversationId,
  timeout,
  unreadTimeout
) => {
  unreadTimeout = setTimeout(() => {
    dispatch(markUnreadMessagesAction(conversationId));
  }, timeout);
};

export { playSound } from 'app/util/soundUtils';

export const removeAttachment = (index, formValues, setFormValues) => {
  const { attachments } = formValues;

  const newAttachments = [...attachments];
  newAttachments.splice(index, 1);

  setFormValues({
    ...formValues,
    attachments: newAttachments,
  });
};

export const setMessagesOpen = (dispatch, isOpen) => {
  dispatch(setMessagesOpenAction(isOpen));
};

/** Scroll to the bottom of the viewport. */
export const scrollToBottom = (animated = false, scrollView) => {
  if (scrollView) scrollView.scrollToOffset({ offset: 0, animated });

  if (window?.scrollTo && document?.body) {
    window.scrollTo({
      top: document.body.scrollHeight,
      // @ts-ignore TODO: fix TS warning
      behavior: animated ? 'smooth' : 'instant',
    });
  }
};
