import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import { Modal as ModalRaw } from 'app/components/Common/Modal';
import MessagesRaw from 'app/components/Messages';
import { View } from 'app/components/Common/Styled';

export const Modal = styled(ModalRaw).attrs(({ theme }) => ({
  overlayStyle:
    Platform.OS === 'web'
      ? {
          height: '90%',
          maxHeight: theme.breakpoints.small,
          maxWidth: theme.breakpoints.medium,
          width: '90%',
        }
      : {
          height: theme.breakpoints.shortScreen,
        },
}))``;

export const Messages = styled(MessagesRaw)`
  ${({ theme }) => css`
    height: ${theme.breakpoints.shortScreen}px;
  `};
`;

export const InnerContainer = styled(View)`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  ${({ theme }) => css`
    height: ${theme.breakpoints.shortScreen}px;
  `};
`;
