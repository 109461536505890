import React from 'react';
import { Icon } from 'react-native-elements';
import styled, { css } from 'styled-components/native';

import {
  receiveSetCallUsModalVisibility,
  receiveSetLiveChatModalVisibility,
} from 'app/actions/uiActions';
import TrackableButton from 'app/components/Common/TrackableButton';
import { useDispatch } from 'app/components/Messages/hooks';

const ScheduleCallButton = () => {
  const dispatch = useDispatch();

  const handleButtonPress = () => {
    // ensure live chat modal is closed
    dispatch(receiveSetLiveChatModalVisibility(false));
    // open call schedule modal
    dispatch(receiveSetCallUsModalVisibility(true));
  };

  return (
    <Button
      icon={<CalendarIcon />}
      onPress={handleButtonPress}
      title="Schedule a call"
    />
  );
};

export default ScheduleCallButton;

const CalendarIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'calendar-today',
  color: theme.colors.primary,
  size: theme.spacing,
}))`
  ${({ theme }) => css`
    margin-right: ${theme.spacing / 2}px;
  `}
`;

const Button = styled(TrackableButton).attrs({
  type: 'outline',
  containerStyle: {
    alignItems: 'baseline',
  },
  buttonStyle: {
    padding: 4,
  },
})``;
