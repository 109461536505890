import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

interface IdleMoonProps {
  height?: number;
  width?: number;
}

const IdleMoon = ({ height = 4, width = 4 }: IdleMoonProps) => {
  return (
    <Svg width={width} height={height} viewBox={`0 0 8 8`} fill="none">
      <Circle
        cx="4"
        cy="4"
        r="3.73333"
        fill="white"
        stroke="white"
        stroke-width="0.533333"
      />
      <Path
        d="M3.57796 1C5.23198 1 6.57127 2.3433 6.57127 4C6.57127 5.6567 5.23198 7 3.57796 7C2.76636 7 2.03109 6.67589 1.49136 6.15089C1.42439 6.08527 1.40698 5.98348 1.44984 5.90045C1.4927 5.81741 1.58511 5.77054 1.67752 5.78661C1.80877 5.80937 1.9427 5.82143 2.08064 5.82143C3.37841 5.82143 4.43109 4.76607 4.43109 3.46429C4.43109 2.58304 3.94894 1.81563 3.23511 1.41116C3.15341 1.36429 3.11189 1.27054 3.13198 1.17946C3.15207 1.08839 3.22975 1.02009 3.3235 1.01205C3.40787 1.00536 3.49225 1.00134 3.57796 1.00134V1Z"
        fill="#F3BD48"
      />
    </Svg>
  );
};

export default IdleMoon;
