import {
  DotIndicator,
  Notification,
  NotificationContainer,
} from 'app/components/Messages/styles';

/**
 * Render the default content when there are no messages.
 *
 * @param  {boolean}  isLoading  whether the conversation is loading
 */
const MessageListEmpty = ({ isLoading = true }) => {
  if (isLoading) {
    return (
      <NotificationContainer>
        <Notification>Loading messages...</Notification>
        <DotIndicator />
      </NotificationContainer>
    );
  }

  return <Notification>You have no messages</Notification>;
};

export default MessageListEmpty;
