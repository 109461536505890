import {
  InnerContainer,
  Messages,
  Modal,
} from 'app/components/LiveChatModal/styles';

interface LiveChatModalProps {
  visible: boolean;
  onClose: () => void;
}

export const LiveChatModal = ({ visible, ...props }: LiveChatModalProps) => {
  const onClose = () => {
    props.onClose();
  };

  return (
    <Modal isVisible={visible} onClose={onClose}>
      <InnerContainer>
        <Messages />
      </InnerContainer>
    </Modal>
  );
};
